/* Settings */
:root {
  --trans-white: #ffffffe0;
  --ash-gray: #cad2c5ff;
  --dark-sea-green: #84a98cff;
  --logo-color: #5DB094;
  --hookers-green: #52796fff;
  --dark-slate-gray: #354f52ff;
  --charcoal: rgb(28, 36, 41);
  --dark-charcoal: rgb(17, 21, 24);
}

@font-face {
  font-family: RedHatMedium;
  src: url("./fonts/RedHatText/static/RedHatText-Medium.ttf");
}

@font-face {
  font-family: RedHatItalics;
  src: url("./fonts/RedHatText/static/RedHatText-MediumItalic.ttf")
}

.App {
  background-color: rgb(255, 255, 255);
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

/* PopModal */
.modal-open {
  padding-right: 0!important;
}

.modal-top-header {
  display: inline-block!important;
  text-align: center;
}

.btn-close {
  float: right;
}

.modal-submit {
  background-color: var(--logo-color)!important;
  color: #fff!important;
}

.modal-content {
  border-radius: 0!important;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.95)!important;
}

.modal-bottom-header {
  margin: 0;
}

.modal-top-title {
  display: inline-block!important;
}


/* Header */
.App .header {
  font-size: clamp(15px, 1.5vw, 24px);
  position: fixed;
  width: 100vw;
  background-color: var(--trans-white);
  z-index: 2;
}

.logo {
  height: 100px;
}

.navbar-collapse .navbar-nav {
  margin: 0 auto;
  width: 90%;
  justify-content: space-around;
}

.navbar-nav > a {
  margin: 0;
  color: var(--charcoal)!important;
  font-family: RedHatMedium;
}

/* Home */
.App .home-banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* calc(50vh + 50vw) */
  height: clamp(50vh, 100vh, 1000px)!important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: -1;
}

#home {
  position: relative;
  z-index: 1;
}

.home, .about {
  z-index: 1;
}

.home .home-title-container {
  padding-top: 6vh;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.home-title-container .title-intro {
  color: white;
  font-family: RedHatItalics;
  font-size: clamp(30px, 6vw, 72px);
}

.home-title-container .title-overstock {
  color: var(--logo-color);
  font-family: RedHatMedium;
  font-size: clamp(48px, 12vw, 144px);
  line-height: 1;
}

.home-title-container .title-end {
  color: white;
  font-family: RedHatMedium;
  font-size: clamp(15px, 3vw, 36px);
  margin-top: 10px;
}

.home-title-container .title-hr {
  color: white;
}

.home-title-container .title-button {
  font-family: RedHatItalics;
  border-radius: 0px;
  font-size: clamp(10px, 2vw, 20px);
  padding: clamp(10px, 2vw, 20px);
  background: linear-gradient(0deg, rgb(19, 24, 27) 0%, rgb(22, 23, 24) 100%);
}

.home-title-container .title-button, 
.home-title-container .title-button:active, 
.home-title-container .title-button:focus {
  border-color: rgb(19, 24, 27);
  box-shadow: none;
}

.home-title-container .title-button:focus {
  background-color: var(--charcoal);
}

.home-title-container .title-button:hover {
  background-color: var(--dark-charcoal);
  border-color: var(--charcoal)
}

.angle-container {
  margin-top: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: clamp(24px, 4vw, 40px);
  color: white;
}

.gradient-trans {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255,255,255,1) 50%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 1;
  
}

.trans-top {
  margin-top: -40px;
}

.trans-bottom {
  margin-bottom: -40px;
}

/* Home Transition */
.App .lvf-banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: clamp(300px, 50vw, 600px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.lvf-banner .lvf-title-container {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.lvf-title-container .lvf-title-intro {
  color: white;
  font-family: RedHatItalics;
  font-size: clamp(20px, 4vw, 60px);
}

.lvf-title-container .lvf-title-lvp {
  color: var(--logo-color);
  font-family: RedHatMedium;
  font-size: clamp(30px, 6vw, 90px);
  text-transform: uppercase;
}

.home-trans1 {
  margin-bottom: 50px;
}

.home-trans1 .trans1-text-col {
  background-color: rgba(90, 90, 90, 0);
  padding: 0;
}

.home-trans1 .trans1-box-left,
.home-trans1 .trans1-box-right {
  display: flex;
  align-items: center;
}

.home-trans1 .trans1-title {
  color: var(--logo-color);
  font-family: RedHatItalics;
  font-size: clamp(30px, 5vw, 70px);
  text-align: center;
  white-space: nowrap;
  margin-bottom: 45px;
}

.home-trans1 .trans1-text {
  color: rgb(29, 29, 29);
  font-family: RedHatMedium;
  text-align: center;
  font-size: clamp(15px, 2vw, 25px);
  margin-bottom: 0;
  padding: 0 25px;
}

.home-trans1 .trans1-title-text {
  color: var(--logo-color);
  font-family: RedHatMedium;
  text-align: center;
  font-size: clamp(30px, 4vw, 50px);
  text-transform: uppercase;
}

.trans1-img-col {
  width: 90%;
  margin: 0 auto;
}

.triangle-left-top {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 400px 30px 0 0;
  border-color: white transparent transparent transparent;
  position: relative;
  z-index: 3;
}

.triangle-right-bottom {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 400px 30px;
  border-color: transparent transparent white transparent;
  line-height: 0px;
  position: relative;
  z-index: 3;
  margin-left: auto;
}

.triangle-right-top {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 400px 0;
  border-color: transparent white transparent transparent;
  margin-left: auto;
  position: relative;
  z-index: 3;
}

.triangle-left-bottom {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 400px 0 0 30px;
  border-color: transparent transparent transparent white;
  position: relative;
  z-index: 3;
}

.trans1-box-right .trans1-text-container, .trans1-box-left .trans1-text-container {
  margin: 20px auto;
}

.parallax-trans1-right {
  height: 200px!important;
}

/* Banner titles */
.App .banner-title {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* calc(50vh + 50vw) */
  height: 40vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: -1;
}

.title-container {
  position: relative;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* About */
.about-title {
  color: var(--logo-color);
  font-family: RedHatItalics;
  font-size: clamp(24px, 6vw, 96px);
  line-height: 1;
}

.about-container {
  width: 100%;
  margin: 0 auto;
}

.about-container .row{
  display: flex;
  justify-content: center;
  max-width: 1500px;
  margin: 0 auto;
}

.about-text-col-right {
  background-color: var(--charcoal);
  display: flex;
  align-items: center;
  background: white;
  /* box-shadow: 5px 5px 8px 0px rgb(173, 173, 173); */
  padding: 0 5%;

}

.about-text-col-left {
  background-color: var(--charcoal);
  display: flex;
  align-items: center;
  background: white;
  /* box-shadow: -5px 5px 8px 0px rgb(173, 173, 173); */
  padding: 0 5%;
}

.about-text-col-left, .about-text-col-right, .about-img-col .parallax-about {
  height: clamp(255px, 33vw, 330px)!important ;
}

.about-text-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 10px;
}

.about-text-title {
  color: var(--logo-color);
  font-family: RedHatMedium;
  text-align: center;
  font-size: clamp(24px, 4vw, 50px);
  margin-top: 10px;
}

.about-text {
  color: var(--charcoal);
  font-family: RedHatMedium;
  text-align: center;
  font-size: clamp(12px, 2vw, 25px);
}

.about-wide-text-col {
  background-color: var(--charcoal);
  display: flex;
  align-items: center;
  background: white;
  height: clamp(165px, 33vw, 303px)!important ;
  /* box-shadow: 0px 5px 8px 5px rgb(173, 173, 173); */
  padding: 0 5%;
}

.about-wide-img-col .parallax-banner-layer-0, .about-wide-img-col .parallax-about {
  height: clamp(132px, 33vw, 330px)!important ;
}

.about-wide-img-col .parallax-banner-layer-0, .about-img-col .parallax-banner-layer-0 {
  inset: 0% 0px!important;
}

.about-img-col .parallax-banner-layer-0 {
  background-position: top center!important;
}

/* Gallery */
.gallery {
  margin-bottom: 50px;
}

.gallery-container {
  padding-top: 40px;
  overflow: auto;
}

.flooring-swiper-container {
  user-select: none;
  height: 430px;
}

.flooring-swiper-container .swiper {
  /* width: 100%;
  padding-top: 50px;
  padding-bottom: 50px; */
  height: 440px;
}

.flooring-swiper-container .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 700px!important;
  height: 375px;
}

.flooring-swiper-container .swiper-slide img {
  width: 100%;
}


.flooring-swiper-animation {
  display: none;
}

.contact-containter-mobile-animation {
  display: block;
}


/* Reviews */
.reviews-swiper-container {
  margin: 50px auto 25px auto;
  width: 51%;
  max-width: 1250px;
  text-align: center;
  user-select: none;
}

.reviews-swiper-container .swiper-slide {
  max-width: 400px;
  margin-bottom: 50px;
  /* min-width: 200px; */
}

.swiper-slide .card {
  /* background: linear-gradient(0deg, rgba(212,212,212,1) 0%, rgba(237,237,237,1) 100%); */
  border: none;
}

.card .card-img-top {
  width: clamp(150px, 30vw, 240px);
  margin: 0 auto;
  padding-top: 20px;
}

.card .card-body {
  /* height: 200px; */
  flex-direction: column;
  justify-content: center;
  
}

.card .card-title {
  font-size: clamp(20px, 5vw, 40px);
  font-family: RedHatMedium;
}

.card-text-container {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: center;
}

.card .card-text {
  font-size: clamp(15px, 2.5vw, 20px);
  font-family: RedHatItalics;
}

.review-cards {
  width: 90%;
  margin: 0 auto;
  max-width: 1250px;
}

.review-card {
  display: flex;
  margin-bottom: 50px;
}

.review-card-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 15px;
  background: linear-gradient(0deg, rgba(212,212,212,1) 0%, rgba(237,237,237,1) 100%);
}

.review-card-title {
  font-family: RedHatMedium;
  font-size: clamp(24px, 4vw, 40px);
  margin-bottom: 20px;
}

.review-card-body {
  font-family: RedHatItalics;
  font-size: clamp(12px, 2vw, 20px);
}

.review-card-banner {
  width: 40vw!important;
  height: 400px!important;
}

.review-card-banner .parallax-banner-layer-1{
  background-size: contain!important;
  background-repeat: no-repeat;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--logo-color);
}

/* Contact */
/* .contact-containter-animation {
  display: none;
} */

.contact-container {
  margin: 20px auto;
  width: 90%;
  max-width: 1250px;
  text-align: center;
  margin-bottom: 40px;
}


.contact-map-container .contact-map {
  width: 100%;
  height: 70vw;
}

.contact-info-container {
  /* box-shadow: 0px 5px 8px 5px rgb(173, 173, 173); */
  background: linear-gradient(0deg, rgba(212,212,212,1) 0%, rgba(237,237,237,1) 100%);
  font-family: RedHatMedium;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.contact-info {
  padding: 10px 0;
}

.contact-left, .contact-middle, .contact-right {
  width: 100%;
  padding: 10px 0px;
}

.contact-hours, .contact-info {
  font-size: clamp(15px, 3vw, 21px);
  color: var(--dark-charcoal);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0!important;
}

.contact-info-title, .contact-hours-title {
  font-size: clamp(28px, 4vw, 50px);
  color: var(--logo-color);
  margin-bottom: 15px;
}

.contact-icons-container {
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
}

.contact-icons-container {
  font-size: clamp(30px, 4vw, 50px);
}

.contact-icons-left {
  margin-right: 10px;
}

.contact-icons-container a, .contact-info a {
  color: var(--dark-charcoal);
  text-decoration: none;
}

.contact-info a:hover {
  color: var(--dark-charcoal);
  text-decoration: underline;
}

.yelp-link:hover {
  color: rgb(211, 21, 21);
}

.insta-link:hover {
  color: rgb(155 46 163);
}

.fb-link:hover {
  color: rgb(22, 67, 193);
}

.contact-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.hours-flex-container {
  display: flex;
  flex-direction: column;
}

.fa-phone-alt, .fa-map-marker-alt {
  font-size: 20px;
}

.fa-yelp, .fa-instagram, .fa-facebook-square {
  font-size: 40px;
}

.contact-left {
  order: 1;
}

.contact-middle {
  order: 3;
}

.contact-right {
  order: 2;
}

/* Breakpoints */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 576px) {
  .App .banner-title {
    height: 20vh !important;
  }

  .parallax-about, .about-text-col {
    height: 200px!important ;
  }

  .gallery-container {
    margin: 0 20px;
  }

  .about-container {
    padding: 0 15px;
  }

  .row .wide-img-col-container {
    padding: 0 8px;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 576px) {
  .trans1-container {
    position: relative;
    display: block;
  }

  .gallery-container {
    margin: 0 40px;
  }

  .about-container {
    padding: 0 25px;
  }

  .row .wide-img-col-container {
    padding: 0 12px;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  .trans1-container {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
  }

  .trans1-container .order-1 {
    margin-left: -30px;
  }
  
  .trans1-container .order-2 {
    margin-right: 30px;
  }

  .trans1-box-right .trans1-text-container {
    margin-right: 25px;
  }
  
  .trans1-box-left .trans1-text-container {
    margin-left: 25px;
  }

  .gallery-container {
    margin: 0 100px;
  }

  .about-container {
    padding: 0 45px;
  }

  .row .wide-img-col-container {
    padding: 0 24px;
  }

  .trans1-img-col {
    width: 100%;
  }

  .parallax-trans1-right {
    height: 400px!important;
  }

  .reviews-swiper-container {
    width: 80%;
  }

  .card .card-img-top {
    width: clamp(150px, 20vw, 300px);
  }

  .card .card-title {
    font-size: clamp(26px, 3vw, 40px);
  }

  .card .card-text {
    font-size: clamp(13px, 1.5vw, 20px);
  }

  .review-card-text {
    padding: 30px;
  }

  .contact-map-container .contact-map {
    height: 28vw;
  }

  .contact-info-container {
    flex-direction: row;
  }

  .contact-left, .contact-middle, .contact-right {
    width: 33.33%;
    padding: 20px;
  }

  .contact-flex-container {
    flex-direction: column;
    justify-content: center;
  }

  .contact-info a {
    padding: 5px 0;
    white-space: nowrap;
  }

  .hours-flex-container {
    padding-top: 10px;
  }

  .fa-phone-alt, .fa-map-marker-alt {
    font-size: inherit
  }

  .fa-yelp, .fa-instagram, .fa-facebook-square {
    font-size: inherit
  }

  .contact-info {
    padding: 5px 0;
  }

  .contact-hours, .contact-info {
    font-size: clamp(18px, 2vw, 25px);
  }

  .contact-left {
    order: 1;
  }
  
  .contact-middle {
    order: 2;
  }
  
  .contact-right {
    order: 3;
  }

  .contact-icons-container {
    padding-top: 25px;
  }

  .contact-info-title, .contact-hours-title {
    margin-bottom: 15px;
  }

  .contact-container {
    margin: 20px auto;
    width: 90%;
  }

  .review-cards {
    width: 80%;
  }
  
  .about-text-container {
    margin: 0 20px;
  }

  .modal-bottom-header {
    margin: auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .navbar-nav > a {
    margin: 0 30px;
  }

  .gallery-container {
    margin: 0 160px;
  }

  .about-container {
    padding: 0 65px;
  }

  .flooring-swiper-animation {
    display: block;
    margin-top: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

  /* .gallery-container {
    margin: 0 200px;
  } */
  /* .App .header {
    font-size: 24px;
  } */

  .about-container {
    padding: 0 85px;
  }

  
}

/* Extra extra large devices (large laptops and desktops, 1400px and up) */
/* @media only screen and (min-width: 1400px) {

} */